import { api, resolvePagination } from './api';

/**
 * informação do utilizador (nome, fuso horário, língua (locale), e contas a que tem acesso (roles))
 * @returns {Promise<any>}
 */
export const getUser = async () => api.get('/panel/user');
/**
 * Update user settings
 * @param {string} name
 * @param {string} timezone
 * @param {string} locale
 * @returns {Promise<any>}
 */
export const patchUser = async ({ name, timezone, locale }) => api.patch('/panel/user', { name, timezone, locale });

/**
 * Get all users by owner associate
 * @returns {Promise<any>}
 */
export const getUsers = async ({ pageToken, pageLimit }) => {
    const headers = resolvePagination(pageToken, pageLimit);
    return api.get('/panel/users', { headers });
};

/**
 * add a new user
 * @param {string} name
 * @param {string} email
 * @param {string} role
 * @returns {Promise<any>}
 */
export const postUser = async (name, email, role) =>
    api.post('/panel/users', {
        name,
        email,
        role
    });

export const mutationPostUser = ({ name, email, role }) => postUser(name, email, role);

/**
 * make a soft-delete (disable) in user
 * @param {string} roleUUID
 * @returns {Promise<any>}
 */
export const deleteUser = async ({ roleUUID }) => api.delete('/panel/users', { data: { role_uuid: roleUUID } });

/**
 * change the role of user
 * @param {string} role
 * @param {string} roleUUID
 * @returns {Promise<any>}
 */
export const patchUsers = async ({ role, roleUUID }) =>
    api.patch('/panel/users', {
        role_uuid: roleUUID,
        role_name: role
    });

/**
 * mudar as permissões de acesso a localizações (owners) de um utilizador (não ADMIN)
 * @param {string} roleUUID
 * @param {array<string>} toAdd
 * @param {array<string>} toRemove
 * @returns {Promise<any>}
 */
export const putUsers = async (roleUUID, toAdd, toRemove) =>
    api.put('/panel/users', {
        role_uuid: roleUUID,
        to_add: toAdd,
        to_remove: toRemove
    });

export const queryPutUsers = async ({ queryKey }) => {
    const [, { uuid }] = queryKey;
    return putUsers(uuid, [], []);
};
export const mutationPutUsers = ({ uuid, toAdd = [], toRemove = [] }) => putUsers(uuid, toAdd, toRemove);
/**
 * Retrieve a list of the owners the user has access to. Uses pagination.
 * @returns {Promise<any>}
 */
export const getUserOwners = async (pageToken, pageLimit = 10) => {
    const headers = resolvePagination(pageToken, pageLimit);
    return api.get('/panel/user/owners', { headers });
};

/**
 * search users
 * @returns {Promise<any>}
 */
export const postUserSearch = async ({ query, pageToken, pageLimit = 10 }) => {
    const headers = resolvePagination(pageToken, pageLimit);
    return api.post(
        '/panel/users/search ',
        {
            query
        },
        { headers }
    );
};
/**
 * Change user picture
 * @returns {Promise<any>}
 * @param {object} image
 */
export const postUserImage = async (image) =>
    api.post(
        '/panel/user/picture ',
        {
            picture: image
        },
        {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
    );
