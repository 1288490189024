import { createSlice } from '@reduxjs/toolkit';

export const groups = createSlice({
    name: 'groups',
    initialState: {
        groupTag: []
    },
    reducers: {
        updateGroupTag: (state, action) => {
            state.groupTag = action.payload.groupTag;
        }
    }
});

export const groupTag = (state) => state.groups.groupTag;

export const { updateGroupTag } = groups.actions;

export default groups.reducer;
