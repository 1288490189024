import { createSlice } from '@reduxjs/toolkit';

/**
 * Reducer for management of data in component 'Records'.
 */
export const record = createSlice({
    name: 'record',
    initialState: {
        selectedRecord: null,
        records: []
    },
    reducers: {
        updateRecordInfo: (state, action) => {
            state.selectedRecord = action.payload;
        },
        updateRecordData: (state, action) => {
            state.selectedRecord?.parse(action.payload);
        },
        resetRecords: (state) => {
            state.selectedRecord = null;
            state.records = [];
        }
    }
});

export const { updateRecordInfo, updateRecordData, resetRecords } = record.actions;

export default record.reducer;
