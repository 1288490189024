import { useRoutes } from 'react-router-dom';
// routes
import MainRoutes from './mainRoutes';
import AuthenticationRoutes from './authenticationRoutes';
import config from '../config/config';
import ErrorRoutes from './errorRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export const routes = [MainRoutes, AuthenticationRoutes, ErrorRoutes];

const ThemeRoutes = () => useRoutes(routes, config.basename);

export default ThemeRoutes;
