/**
 * Color intention that you want to used in your theme
 * @param theme Theme customization object
 */

const themePalette = (theme) => {
    const { navType } = theme.customization;
    const {
        paper,
        darkPaper,
        primaryLight,
        primaryMain,
        primaryDark,
        primary200,
        primary800,
        secondaryLight,
        secondaryMain,
        secondaryDark,
        secondary200,
        secondary800,
        errorLight,
        errorMain,
        errorDark,
        orangeLight,
        orangeMain,
        orangeDark,
        warningLight,
        warningMain,
        warningDark,
        successLight,
        success200,
        successMain,
        successDark,
        grey50,
        grey100,
        darkTextPrimary,
        darkLevel1,
        darkLevel2,
        darkBackground
    } = theme.colors;
    return {
        mode: navType,
        common: {
            paper,
            black: darkPaper
        },
        primary: {
            light: primaryLight,
            main: primaryMain,
            dark: primaryDark,
            200: primary200,
            800: primary800
        },
        secondary: {
            light: secondaryLight,
            main: secondaryMain,
            dark: secondaryDark,
            200: secondary200,
            800: secondary800
        },
        error: {
            light: errorLight,
            main: errorMain,
            dark: errorDark
        },
        orange: {
            light: orangeLight,
            main: orangeMain,
            dark: orangeDark
        },
        warning: {
            light: warningLight,
            main: warningMain,
            dark: warningDark
        },
        success: {
            light: successLight,
            200: success200,
            main: successMain,
            dark: successDark
        },
        grey: {
            50: grey50,
            100: grey100,
            500: theme.darkTextSecondary,
            600: theme.heading,
            700: theme.darkTextPrimary,
            900: theme.textDark
        },
        dark: {
            light: darkTextPrimary,
            main: darkLevel1,
            dark: darkLevel2,
            800: darkBackground,
            900: darkPaper
        },
        text: {
            primary: theme.darkTextPrimary,
            secondary: theme.darkTextSecondary,
            dark: theme.textDark,
            hint: grey100
        },
        background: {
            paper: theme.paper,
            default: theme.backgroundDefault
        }
    };
};

export default themePalette;
