import { Outlet } from 'react-router-dom';
// material-ui
import { styled } from '@mui/material/styles';
import logo from 'assets/images/logo.png';
import { Grid } from '@mui/material';
import AuthFooter from 'components/cards/AuthFooter';
// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

export const AuthWrapper = styled('div')(() => ({
    // ({ theme })
    backgroundColor: '#FFFAEB',
    minHeight: '100vh'
}));

// ==============================|| STYLES ||============================== //

const styles = {
    logo: {
        width: '350px',
        marginBottom: 50
    },
    footer: {
        px: 3,
        pt: 1,
        backgroundColor: 'rgba(0,0,0,0.35)',
        height: '100px'
    }
};

function AuthMainLayout() {
    return (
        <AuthWrapper>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" direction="column" sx={{ minHeight: 'calc(95vh - 68px)' }}>
                        <Grid item>
                            <img src={logo} alt="Logo" style={styles.logo} />
                        </Grid>
                        <Grid item>
                            <Outlet />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={styles.footer}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </AuthWrapper>
    );
}

export default AuthMainLayout;
