import axios from 'axios';
import { refreshToken, validateToken } from 'aws-auth-cardioid';

export const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    }
});

export const updateToken = async (action) => {
    try {
        const token = await refreshToken();
        api.defaults.headers['AUTH-TOKEN-AWS'] = token;
        action.headers['AUTH-TOKEN-AWS'] = token;
    } catch (e) {
        console.log('axios error get token', e);
    }
    return action;
};

export const setPanelRole = (uuid) => {
    api.defaults.headers['PANEL-ROLE'] = uuid;
};

export const setPanelOwner = (uuid) => {
    api.defaults.headers['PANEL-OWNER'] = uuid;
};

export const setToken = (token) => {
    api.defaults.headers['AUTH-TOKEN-AWS'] = token;
};

export const resolvePagination = (pageToken, pageLimit) => {
    const headers = {};
    if (pageToken) {
        headers['PAGINATION-TOKEN'] = pageToken;
    }
    if (pageLimit) {
        headers['PAGINATION-LIMIT'] = pageLimit;
    }
    return headers;
};

api.interceptors.request.use(
    async (config) => {
        if (!config.headers['AUTH-TOKEN-AWS'] || !validateToken(config.headers['AUTH-TOKEN-AWS'])) {
            config = await updateToken(config);
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        let originalRequest = error?.config;
        if (error?.response?.status === 401 && !originalRequest?._retry) {
            originalRequest._retry = true;
            originalRequest = await updateToken(originalRequest);
            return api(originalRequest);
        }
        return Promise.reject(error);
    }
);
