import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import { CookiesProvider } from 'react-cookie';
// mui imports
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

function App() {
    const customization = useSelector(({ customization }) => customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CookiesProvider>
                    <SnackbarProvider maxSnack={4}>
                        <QueryClientProvider client={queryClient}>
                            <CssBaseline />
                            <NavigationScroll>
                                <Routes />
                            </NavigationScroll>
                        </QueryClientProvider>
                    </SnackbarProvider>
                </CookiesProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
