import { createSlice } from '@reduxjs/toolkit';

export const handlerRoleName = (roleName) => {
    switch (roleName) {
        case 'ADMIN':
            return 'Administrator';
        case 'SUPERVISOR':
            return 'Supervisor';
        default:
            return '';
    }
};
export const user = createSlice({
    name: 'account',
    initialState: {
        name: '',
        local: null,
        roles: [],
        timezone: null,
        uuid: null,
        picture: null
    },
    reducers: {
        updateUser: (state, action) => {
            state.name = action.payload.name;
            state.local = action.payload.locale;
            state.roles = action.payload.roles;
            state.timezone = action.payload.timezone;
            state.uuid = action.payload.uuid;
            state.picture = action.payload.picture;
        },
        removeUser: (state) => {
            state.name = '';
            state.local = null;
            state.roles = [];
            state.timezone = null;
            state.uuid = null;
            state.picture = null;
        },
        updatePicture: (state, action) => {
            state.picture = action.payload;
        },
        updateSettings: (state, action) => {
            state.name = action.payload.name;
            state.local = action.payload.locale;
            state.timezone = action.payload.timezone;
        }
    }
});

export const userRoles = (state) => state.user.roles;

export const { updateUser, removeUser, updatePicture, updateSettings } = user.actions;

export default user.reducer;
