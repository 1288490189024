import { createSlice } from '@reduxjs/toolkit';

/**
 * Reducer for management of data in component LiveView.
 */
export const live = createSlice({
    name: 'liveView',
    initialState: {
        critical: [],
        assets: [],
        assetsFiltered: [],
        assetsSelected: [],
        query: ''
    },
    reducers: {
        addDataLive: (state, action) => {
            const { critical, assets } = action.payload;
            state.critical = critical;
            state.assets = assets;
            state.assetsFiltered = assets;
            state.assetsSelected = assets;
            state.idsSelected = assets.map(({ host }) => host.uuid);
        },
        updateAssetsFiltered: (state, action) => {
            state.assetsFiltered = action.payload;
        },
        updateAssetsSelected: (state, action) => {
            state.assetsSelected = action.payload.assets;
            state.idsSelected = action.payload.ids;
        },
        updateQuery: (state, action) => {
            state.query = action.payload;
        }
    }
});

export const { addDataLive, updateAssetsFiltered, updateAssetsSelected, updateQuery } = live.actions;

export default live.reducer;
