import * as CryptoJS from 'crypto-js';

export const encryptAES = (data, key = process.env.REACT_APP_COOKIE_KEY) => CryptoJS.AES.encrypt(data, key).toString();

export const decryptAES = (encryptedBase64, key = process.env.REACT_APP_COOKIE_KEY) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key);
    if (decrypted) {
        try {
            const str = decrypted.toString(CryptoJS.enc.Utf8);
            if (str.length > 0) {
                return str;
            }
            return null;
        } catch (e) {
            return null;
        }
    }
    return null;
};

export const base64ToArrayBuffer = (base64) => {
    let binary = '';
    const bytes = new Uint8Array(base64);
    for (let i = 0; i < bytes.byteLength; i += 1) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};
