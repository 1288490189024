import ReactDOM from 'react-dom';
// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// project imports
import * as serviceWorker from 'serviceWorker';
import './i18n/index';
import App from 'App';
import { store } from 'store';
// style + assets
import 'assets/scss/style.scss';

// ==============================|| REACT DOM RENDER  ||============================== //

import { AmplifyConfigure } from 'aws-auth-cardioid';

const authConfig = {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOLWEBCLIENTID
};

ReactDOM.render(
    <Provider store={store}>
        <AmplifyConfigure config={authConfig}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </AmplifyConfigure>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
