import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';
import AuthMainLayout from 'views/auth/layouts/AuthWrapper1';

// Pages
const NotFoundPage = Loadable(lazy(() => import('views/error/NotFoundPage').then((screen) => screen)));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const ErrorRoutes = {
    path: '*',
    element: <AuthMainLayout />,
    children: [
        {
            path: '*',
            element: <NotFoundPage />
        }
    ]
};

export default ErrorRoutes;
