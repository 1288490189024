// material-ui
import { Link, Typography, Stack, ListItem, ListItemIcon, ListItemText, List, Box } from '@mui/material';
import { Email } from '@mui/icons-material';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //
const styles = {
    typography: {
        color: '#fff'
    },
    typographySub: {
        color: '#fff',
        fontSize: '10px',
        width: '250px'
    },
    list: {
        display: 'flex',
        justifyContent: 'center'
    },
    listItemIcon: {
        color: '#fff'
    },
    content: {
        textAlign: 'center',
        alignItems: 'center',
        flex: '0 0 auto'
    },
    content1: {
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        py: 1
    },
    stack: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row'
    }
};

const contacts = [
    {
        icon: <Email />,
        title: 'support@cardio-id.com | Monday to Friday, from 9:00 at 17:00',
        subtitle: null
    }
    // {
    //     icon: <Call />,
    //     title: 'Monday to Friday, from 9:00 at 17:00',
    //     subtitle: null
    // }
];

function AuthFooter() {
    return (
        <Stack sx={styles.stack}>
            <Box sx={styles.content1}>
                <Typography
                    variant="subtitle2"
                    component={Link}
                    target="_blank"
                    href="https://cardio-id.com/"
                    underline="hover"
                    sx={styles.typography}
                >
                    www.cardio-id.com
                </Typography>
            </Box>
            <Box sx={styles.content}>
                <Typography variant="subtitle1" sx={styles.typography}>
                    Trouble accessing your account?
                </Typography>
                <List disablePadding sx={styles.list}>
                    {contacts.map((item, index) => (
                        <ListItem key={index} disablePadding dense>
                            <ListItemIcon sx={styles.listItemIcon}>{item.icon}</ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle1" sx={styles.typography}>
                                        {item.title}
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant="subtitle2" sx={styles.typographySub}>
                                        {item.subtitle}
                                    </Typography>
                                }
                                sx={styles.listItemText}
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Box sx={styles.content1}>
                <Typography variant="subtitle2" target="_blank" sx={styles.typography}>
                    2024 &copy; CardioID Technologies Lda.
                </Typography>
            </Box>
        </Stack>
    );
}

export default AuthFooter;
