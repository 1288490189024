// project imports
import config from 'config/config';

// action - state management
import * as actionTypes from '../actions';

export const initialState = {
    opened: config.mainMenu.isOpen
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const menuReducer = (state = initialState, action = null) => {
    switch (action.type) {
        case actionTypes.OPEN_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        default:
            return state;
    }
};

export default menuReducer;
