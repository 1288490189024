import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';
import AuthMainLayout from 'views/auth/layouts/AuthWrapper1';

// login option 3 routing
const Login = Loadable(lazy(() => import('views/auth/Login').then((screen) => screen)));
const ChangePassword = Loadable(lazy(() => import('views/auth/changePassword').then((screen) => screen)));
const ForgotPassword = Loadable(lazy(() => import('views/auth/forgotPassword').then((screen) => screen)));
const ForgotPasswordSubmit = Loadable(lazy(() => import('views/auth/forgotPasswordSubmit').then((screen) => screen)));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <AuthMainLayout />,
    children: [
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/changePassword',
            element: <ChangePassword />
        },
        {
            path: '/forgotPassword',
            element: <ForgotPassword />
        },
        {
            path: '/forgotPasswordSubmit',
            element: <ForgotPasswordSubmit />
        }
    ]
};

export default AuthenticationRoutes;
