import { combineReducers } from 'redux';
// reducer import
import customizationReducer from './reducers/customizationReducer';
import menuReducer from './reducers/menuReducer';
import userReducer from './reducers/userInfoReducer';
import RecordReducer from './reducers/recordReducer';
import groupsReducer from './reducers/groups';
import LiveReducer from './reducers/liveViewReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    menu: menuReducer,
    user: userReducer,
    record: RecordReducer,
    groups: groupsReducer,
    live: LiveReducer
});

export default reducer;
