import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getIdToken, RequireAuth } from 'aws-auth-cardioid';

import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setPanelOwner, setPanelRole, setToken } from 'services/apiFleet/api';
import { getUser } from 'services/apiFleet/users';
import { updateUser } from '../store/reducers/userInfoReducer';
import AuthMainLayout from '../views/auth/layouts/AuthWrapper1';
import { useDatabaseCookie, useOwnerCookie } from '../hooks/useOwnerCookie';
// services

// dashboard routing
const Main = Loadable(lazy(() => import('layout/Main').then((screen) => screen)));
const SelectDatabase = Loadable(lazy(() => import('views/auth/SelectDatabase').then((screen) => screen)));
const Dashboard = Loadable(lazy(() => import('views/main/dashboard').then((screen) => screen)));
const LiveView = Loadable(lazy(() => import('views/main/liveView').then((screen) => screen)));
const Records = Loadable(lazy(() => import('views/main/records').then((screen) => screen)));
const Vehicles = Loadable(lazy(() => import('views/main/crews/vehicles').then((screen) => screen)));
const Operators = Loadable(lazy(() => import('views/main/crews/operators').then((screen) => screen)));
const Analytics = Loadable(lazy(() => import('views/main/analytics').then((screen) => screen)));
const HeartMetricsAnalytics = Loadable(lazy(() => import('views/main/analytics/heartMetrics').then((screen) => screen)));
const BehaviorMetricsAnalytics = Loadable(lazy(() => import('views/main/analytics/behaviorMetrics').then((screen) => screen)));
const HeartMetrics = Loadable(lazy(() => import('views/main/heartMetrics').then((screen) => screen)));
const Wearables = Loadable(lazy(() => import('views/main/devices/wearables').then((screen) => screen)));
const Gateways = Loadable(lazy(() => import('views/main/devices/gateways').then((screen) => screen)));
const CardioWheels = Loadable(lazy(() => import('views/main/devices/cardioWheels').then((screen) => screen)));

// utilities routing
const Setting = Loadable(lazy(() => import('views/main/settings').then((screen) => screen)));
const Management = Loadable(lazy(() => import('views/main/management').then((screen) => screen)));
// ==============================|| MAIN ROUTING ||============================== //
function NavigateLogin() {
    setPanelRole(null);
    setPanelOwner(null);
    setToken(null);
    return <Navigate to="/login" replace />;
}
function AuthRoute() {
    return (
        <RequireAuth action={NavigateLogin}>
            <Outlet />
        </RequireAuth>
    );
}

const validateCookieAccount = (database, roles = []) => {
    if (database && roles.length > 0) return !!roles.find((role) => role.uuid === database.uuid);
    return false;
};

const validateAdmin = (pathName, db, roles) => {
    const adminPaths = ['/management'];
    return adminPaths.includes(pathName) && db.name !== 'ADMIN' ? <Navigate to="/" state={{ roles }} replace /> : <Outlet />;
};

const validateOwner = (owner) => !!owner;

function UserRoute() {
    const dispatch = useDispatch();
    const token = getIdToken().getJwtToken();
    const [database] = useDatabaseCookie();
    const [owner] = useOwnerCookie();
    const { pathname } = useLocation();

    const { data, status, isLoading, isFetching } = useQuery('user', getUser, {
        enabled: !!token,
        onSuccess: (res) => {
            const db = res?.data.roles.find((role) => role.uuid === database?.uuid) || res?.data.roles[0];
            setPanelRole(db?.uuid);
            setPanelOwner(owner?.uuid);
            dispatch(updateUser(res?.data));
        }
    });

    if (isLoading || isFetching) {
        return null;
    }
    if (status === 'error') {
        return <Navigate to="/login" replace />;
    }
    if (status === 'success') {
        return validateCookieAccount(database, data?.data?.roles) && validateOwner(owner) ? (
            validateAdmin(pathname, database, data?.data?.roles)
        ) : (
            <Navigate to="/database" state={{ roles: data?.data?.roles }} replace />
        );
    }
    return null;
}

const MainRoutes = {
    path: '/',
    element: <Main />,
    children: [
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'liveview',
            element: <LiveView />
        },
        {
            path: 'records',
            children: [
                {
                    path: 'heartMetrics',
                    element: <HeartMetrics />
                },
                {
                    path: '',
                    element: <Records />
                }
            ]
        },
        {
            path: 'vehicles',
            element: <Vehicles />
        },
        {
            path: 'operators',
            element: <Operators />
        },
        {
            path: 'analytics',
            children: [
                {
                    path: 'heartMetrics',
                    element: <HeartMetricsAnalytics />
                },
                {
                    path: 'behaviorMetrics',
                    element: <BehaviorMetricsAnalytics />
                },
                {
                    path: '',
                    element: <Analytics />
                }
            ]
        },
        {
            path: 'management',
            children: [
                {
                    path: '',
                    element: <Management />
                }
            ]
        },
        {
            path: 'settings',
            children: [
                {
                    path: '',
                    element: <Setting />
                }
            ]
        },
        {
            path: 'gateways',
            element: <Gateways />
        },
        {
            path: 'cardioWheels',
            element: <CardioWheels />
        },
        {
            path: 'wearables',
            element: <Wearables />
        },
        {
            path: '',
            element: <Dashboard />
        }
    ]
};

const routesProtected = {
    path: '/',
    element: <AuthRoute />,
    children: [
        {
            path: '',
            element: <UserRoute />,
            children: [MainRoutes]
        },
        {
            path: 'database',
            element: <AuthMainLayout />,
            children: [
                {
                    path: '',
                    element: <SelectDatabase />
                }
            ]
        }
    ]
};

export default routesProtected;
