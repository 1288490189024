import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { decryptAES, encryptAES } from '../utils/crypto';

const decryptCookie = (cookie) => {
    if (!cookie) return '';
    return JSON.parse(decryptAES(cookie) || '');
};

const encryptCookie = (value) => encryptAES(JSON.stringify(value));

const useDefaultCookie = (cookieName = '', path = '/') => {
    const [cookies, setCookie] = useCookies([cookieName]);
    const [value, setValue] = useState(decryptCookie(cookies[cookieName]));

    const writeValue = (newValue) => {
        const date = new Date();
        date.setDate(date.getDate() + 120);
        setCookie(cookieName, encryptCookie(newValue), { path, expires: date });
    };

    useEffect(() => setValue(decryptCookie(cookies[cookieName])), [cookieName, cookies]);

    return [value, writeValue];
};

export const useEmailCookie = () => useDefaultCookie('email');
export const useOwnerCookie = () => useDefaultCookie('owner');
export const useDatabaseCookie = () => useDefaultCookie('database');
